import React from 'react';

import '../assets/scss/about.scss';
import Accordian from '../components/accordian';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import shape_12 from '../assets/images/about/shape_12.png'
import base3 from '../assets/images/about/base3.png'
import base2 from '../assets/images/about/base2.jpg'
// import base1 from '../assets/images/about/base1.jpg'

import IAccordian from '../interfaces/accordian';
const accordianList: IAccordian[] = [
	{
		title: "About the index",
		desc: `
			<p>Development Finance International and Oxfam International have come together to each use their unique expertise to develop the Commitment to Reducing Inequality Index (CRI Index).</p>
			<p>The index measures the extent to which governments are tackling inequality through their policy choices and actions – such as their commitments to free public healthcare, guaranteeing decent wages or to ensuring that the rich pay their fair share of tax.</p>
			<p>The index doesn’t aim to measure inequality, instead it focuses on what each government is doing to fight inequality in the 158 countries included. This is because inequality is not inevitable or accidental. It is the result of deliberate policy choices. To better fight inequality, it is crucial to monitor what each government is doing and hold them to account - the CRI Index aims to do just that, showing very clearly how governments are performing in relation to each other, and how each country is improving (or not) in fighting inequality.</p>
		`
	},
	{
		title: "About how to use the Index",
		desc: `
			<p>A central aim of the CRI Index is to enable activists to use the data in their own campaigning and advocacy. This is why we work with as many allies as possible to check and validate the data; we also work with global partners, such as the <a target="_blank" href="https://www.fightinequality.org/">Fight Inequality Alliance</a>, and a broad range of national and regional civil society organisations, to actively use the index to hold governments to account.  We also aim to put data and tools in the hands of journalists, advocates, researchers and policy makers, and actively encourage the use of the index by others. The explore data page allows you to look at how your country is doing in one or more of the Pillars (or even indicators in the pillars), as well as the overall Index. It also allows a number of countries to be compared to each other in one or more area.</p>
			<p>Oxfam and DFI believe that transparency and accountability are critical tools in the fight against inequality, which is why we openly <a href="/countryProfiles">publish the data</a> which sits under the CRI Index each year.</p>
			<p>We’re happy to answer any questions and explain how to use the data or index, if you can’t find what you are looking for in the FAQ page or the methodology note. Please email xxx.</p>
		`
	},
	{
		title: "About DFI and Oxfam",
		desc: `
			<p>Development Finance International is a non-profit capacity-building, advocacy, advisory and research group which works with more than 50 governments, international institutions and civil society organisations worldwide to help development financing fight poverty and inequality. For more details see <a target="_blank" href="https://www.development-finance.org/">www.development-finance.org</a> and <a href="https://www.governmentspendingwatch.org/">www.governmentspendingwatch.org</a></p>
			<p>Oxfam is an international confederation of 20 organizations networked together in more than 90 countries, as part of a global movement for change, to build a future free from the injustice of poverty. Please write to any of the agencies for further information, or visit <a href="https://www.oxfam.org/">www.oxfam.org</a></p>
		`
	},
	{
		title: "About our funders and supporters",
		desc: `
			<p>Development Finance International and Oxfam are extremely grateful to the Open Society Foundation, the Gates Foundation and Norwegian Church Aid for financing the work which has produced the Commitment to Reducing Inequality Index.</p>
		`
	}
]

function About() {
	return (
		<section className="about">
			<Carousel showArrows={true} autoPlay={true} infiniteLoop={true} showStatus={false}>
				{[2].map((key) => {
					return (
						<div className="latest-events" key={key}>
							<div className={`banner layer_${key + 5}`}></div>
							<div className="row desc">
								<div className="col-md-9">
									<p className="text-4">A global ranking of governments based on what they are doing to</p>
									<p className="text-5">Tackle the gap between rich and poor</p>
									<p className="text-6">What is your country doing to #fightinequality?</p>
									<div className="row-5-2 clearfix">
										<img className="shape-9" src={shape_12} alt="" />
										<p className="text-8">Oxfam International: we are working with development finance international<br />to fight inequality #FightingInequality</p>
									</div>
									<p className="text-9"><span className="colore2e1e1">Jo Walker</span><br />Development Finance International</p>
								</div>
							</div>
						</div>
					)
				})}
			</Carousel>
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						<img className="base" src={base3} alt="" />
						<div className="text-center text-21">The map above shows the 158 countries covered by the index (shaded darker) and the countries which are not included (shaded lighter)</div>
					</div>
				</div>
				<div className="row row-margin">
					<div className="col-md-4 col-xs-12">
						<img className="base2" src={base2} alt="" />
					</div>
					<div className="col-md-8 col-xs-12">
						<p className="text-title">A GLOBAL RANKING OF GOVERNMENTS BASED ON WHAT THEY ARE DOING TO TACKLE THE GAP BETWEEN RICH AND POOR</p>
						<p className="text-20 text-green">WHAT IS YOUR COUNTRY DOING?</p>
						<p className="text-21">Oxfam and Development Finance International are working with to fight inequality with the #inequalityindex.</p>
					</div>
				</div>
			</div>

			<div className="wedo">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-xs-12">
							<div className="col-3">
								<p><strong className="text-style-3">What we do?</strong></p>
								<p className="text-green">We fight to reduce inequality</p>
								{/* <img className="base1" src={base1} alt="" /> */}
								{/* <video src="https://vimeo.com/465777803"></video> */}
								<iframe title="cri" src="https://player.vimeo.com/video/465777803" width="100%" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
							</div>
						</div>
						<div className="col-md-6 col-xs-12">
							<div className="col-4">
								<Accordian list={accordianList} ></Accordian>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
