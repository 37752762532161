import React, { useEffect } from 'react';
import '../assets/scss/recentBlog.scss';
import { blogList } from '../interfaces/blog';
import connectStore from '../store';

import { IStoreProps } from '../store/interface';

function RecentBlog(props: IStoreProps) {
	const { id } = (props as any).match.params
	const brief = props.blogState.item
	const blog = blogList.find(b => b.id === parseInt(id));

	const { blogActions } = props
	useEffect(() => {
		blogActions.get(`${id}.html`)
	}, [blogActions, id])

	return (
		<section className="recent-blog">
			{ brief && blog ? (
				<div>
					<img className="shape" src={`/images/blogs/${blog.title}.jpg`} alt={blog.title} />
					<div className="container">
						<div className="row clearfix">
							<div className="col-md-12" dangerouslySetInnerHTML={{__html: brief}} ></div>
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</section>
	);
}

export default connectStore(RecentBlog);
