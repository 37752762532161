import { IInequality, InequalityColors, InequalityType, NoDataColor } from "../interfaces/inequality";
import { IFeature } from "../interfaces/feature";
import { GlobalRegion, RegionList } from "../interfaces/region";

declare var d3: any;
declare var topojson: any;

function getTooltip(item: IInequality) {
  if (!item) {
    return '';
  }

  let tooltip = `
    <div class='name'>${item.name}</div>
  `

  for (let key in item.rank) {
    tooltip += `
      <div>
        <span>${key}: </span>
        <span class='value'>#${(item.rank as any)[key]}</span>
      </div>
    `
  }

  return tooltip;
}

export function worldMap(mapEl: any, regionEl: any, typeEl: any, features: IFeature[] = [], data: IInequality[] = []) {

  var elements = mapEl.querySelectorAll('svg');
  if (elements.length > 0) {
    return;
  }

  // const format = d3.format(',');

  // Set tooltips
  // const tip = d3.tip()
  //   .attr('class', 'd3-tip')
  //   .offset([-10, 0])
  //   .html((d: IFeature) => getTooltip(d, data))

  const margin = {top: 0, right: 0, bottom: 0, left: 0};

  const width = mapEl.clientWidth - margin.left - margin.right;
  const height = mapEl.clientHeight - margin.top - margin.bottom;

  const svg = d3.select(mapEl)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('class', 'map');

  const scale = (100 / 640) * width
  const projection = d3.geoMercator()
    .scale(scale)
    .translate( [width / 2, height / 1.5]);

  const path = d3.geoPath().projection(projection);

  const zoom = d3.zoom()
    .scaleExtent([1, 8])
    .on('zoom', () => {
      svg
        .selectAll('path') // To prevent stroke width from scaling
        .attr('transform', d3.event.transform);
    });

  d3.select(mapEl).call(zoom);
  // svg.call(tip);

  var oldTips = document.querySelectorAll('.d3-tip');
  for (let i = 0; i < oldTips.length; i++) {
    const parent = oldTips[i].parentElement;
    if (parent) {
      parent.removeChild(oldTips[i]);
    }
  }
  // Define the div for the tooltip
  const tip = d3.select("body").append("div")
   .attr("class", "d3-tip")
   .style("opacity", 0);

  features.forEach((d: any) => { d.population = data[d.id] });

  svg.append('g')
    .attr('class', 'countries')
    .selectAll('path')
    .data(features)
    .enter().append('path')
      .attr('d', path)
      .style('stroke', 'white')
      .style('opacity', 0.8)
      .style('stroke-width', 0.3)
      // tooltips
      .on('mouseover', (d: IFeature) => {
        const item = data.find((i) => i.code === d.properties.iso_a3);
        if (!item) {
          return;
        }

        d3.select(d3.event.target)
          .style('opacity', 1)
          .style('stroke-width', 1);

        tip.transition()
          .duration(200)
          .style('display', 'unset')
          .style("opacity", .9);

        tip.html(getTooltip(item))
        let { pageX, pageY } = d3.event;

        pageY = pageY - 28;
        if (pageX + 200 > window.innerWidth) {
          pageX = window.innerWidth - 200;
        }

        tip.style("left", pageX + "px")
          .style("top", pageY + "px");
      })
      .on('mouseout', (d: IFeature) => {
        d3.select(d3.event.target)
          .style('opacity', 0.8)
          .style('stroke-width',0.3);

        tip.transition()
          .duration(500)
          .style('display', 'none')
          .style("opacity", 0);
      });

  svg.append('path')
    .datum(topojson.mesh(features, (a: any, b: any) => a.id !== b.id))
    .attr('class', 'names')
    .attr('d', path);

  function onUpdateType () {
    const type: InequalityType = typeEl.value
    const range = [...InequalityColors[type], NoDataColor].reverse();
    const color = d3.scaleThreshold()
      .domain([
        1,
        53,
        106
      ])
      .range(range);

    svg.selectAll('.countries path')
      .each((d: any, i: number, n: any[]) => {
        d3.select(n[i]).style('fill', (d: any) => {
          let item = data.find((i) => i.code === d.properties.iso_a3);
          let score = item ? item.rank[type] : 0;
          if (isNaN(score)) {
            score = -1;
          }

          return color(score);
        })
      })
  }

  regionEl.onchange = (e: any) => {
    const value = e.currentTarget.value;
    const region = RegionList.find(r => r.code === value) || GlobalRegion;

    const scale = region.zoom
    var point = projection(region.center);
    var translate = [width / 2 - point[0] * scale, height / 2 - point[1] * scale]

    d3.select(mapEl).transition()
      .duration(750)
      // .call(zoom.translate(translate).scale(scale).event); // not in d3 v4
      .call(zoom.transform, d3.zoomIdentity.translate(translate[0],translate[1]).scale(scale) ); // updated for d3 v4
  }

  typeEl.onchange = (e: any) => {
    onUpdateType();
  }

  onUpdateType();
}