import { ActionType } from 'typesafe-actions'
import { ICountryBriefState } from './interface'
import { BaseReducer } from '../base/reducer'
import { CountryBriefService } from '../../services/countryBrief'

const baseReducer = new BaseReducer<string>(CountryBriefService.getName())
const initialState: ICountryBriefState = Object.assign(baseReducer.initialState, {

})

const countryBrief = (state = initialState, { type, payload }: ActionType<any>) => {
  const newState: ICountryBriefState = state
  switch (type) {
    default:
      baseReducer.dispatch(newState, { type, payload }).catch()
      break
  }

  return Object.assign({}, state, newState)
}

export default countryBrief
