import { ActionType } from 'typesafe-actions'
import { BaseTypes, IBaseState, getType } from './interface'

export class BaseReducer<T> {
  private name: string = ''

  constructor (name: string) {
    this.name = name
  }

  get initialState (): IBaseState<T> {
    return {
      list: [],
      item: null,
      name: this.name
    }
  }

  async dispatch (state: IBaseState<T> = this.initialState, { type, payload }: ActionType<any>): Promise<IBaseState<T>> {
    const newState: IBaseState<T> = state
    switch (type) {
      case getType(BaseTypes.FETCH, this.name):
        newState.list = payload
        break

      case getType(BaseTypes.GET, this.name):
        newState.item = payload
        break

      // case getType(BaseTypes.ADD, this.name):
      //   newState.list.set(payload.id, payload)
      //   break

      // case getType(BaseTypes.UPDATE, this.name):
      //   newState.list.set(payload.id, payload)
      //   break

      // case getType(BaseTypes.DELETE, this.name):
      //   newState.list.delete(payload.id)
      //   break

      default:
        break
    }

    return Object.assign({}, state, newState)
  }
}
