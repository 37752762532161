import { BaseActions } from '../base/actions'
import { IBlogActions } from './interface'
import { BlogService } from '../../services/blog'

const blogService = new BlogService()
const blogActions: IBlogActions = {
  ...BaseActions.getActions<string>(blogService)
}

export default blogActions
