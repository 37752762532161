import { ActionType } from 'typesafe-actions'
import { IBlogState } from './interface'
import { BaseReducer } from '../base/reducer'
import { BlogService } from '../../services/blog'

const baseReducer = new BaseReducer<string>(BlogService.getName())
const initialState: IBlogState = Object.assign(baseReducer.initialState, {

})

const blog = (state = initialState, { type, payload }: ActionType<any>) => {
  const newState: IBlogState = state
  switch (type) {
    default:
      baseReducer.dispatch(newState, { type, payload }).catch()
      break
  }

  return Object.assign({}, state, newState)
}

export default blog
