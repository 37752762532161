import React from 'react';
import { IInequality } from '../interfaces/inequality';
import { IRegion } from '../interfaces/region';
import { ExporeDataUrl } from '../shared/utils';

interface IProps {
  region: IRegion,
  list: IInequality[]
}

function RegionCarousel(props: IProps) {
  const { region } = props

  return (
    <div key={region.code} className="card">
      <img className="card-img-top" src={region.image} alt={region.name} />
      <div className="card-body">
        <div className="card-text">
          <h5 className="card-title text-6">{region.name}</h5>
        </div>
        <div className="btn-more">
          <a href={ExporeDataUrl} rel="noopener noreferrer" target="_blank">Know more</a>
        </div>
      </div>
    </div>
  )
}

export default RegionCarousel;
