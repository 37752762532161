import { BaseService } from './baseService'

export class CountryBriefService extends BaseService<string> {
  static getName () {
    return 'countryBrief'
  }

  get name () {
    return CountryBriefService.getName()
  }

  protected getUrl () {
    return `/data/countryBrief`;
  }
}
