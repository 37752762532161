export interface IBlog {
	id: number,
  author: string,
  comments: number,
  date: string,
  image: string,
  title: string,
  desc: string
}

export const blogList: IBlog[] = [
	{
		id: 1,
		author: "Matthew Martin, Director DFI",
		comments: 3,
		date: "16 Sep, 20",
		image: "/images/blogs/blog_1.png",
		title: "How the CRI Index is Fighting Inequality",
		desc: "The Commitment to Reducing Inequality Index (or CRI Index for short) is a joint project between Oxfam and Development Finance International. In 2016, we came together to look at how we could use our different strengths to arm activists and policy wonks alike with more information on how to better fight inequality across different countries – the CRI Index was borne out of that."
	},
	{
		id: 2,
		author: "",
		comments: 3,
		date: "15 Sep, 20",
		image: "/images/blogs/blog_2.png",
		title: "Launch of 2020 CRI Index and Report",
		desc: "This is the third edition of the Commitment to Reducing Inequality Index (CRI Index). It ranks 158 governments on their policies on public services, tax and workers’ rights, three areas pivotal to reducing inequality and weathering the COVID-19 storm. It is being launched ahead of the World Bank and International Monetary Fund (IMF) virtual Annual Meetings next week."
	},
	{
		id: 3,
		author: "",
		comments: 3,
		date: "11 Sep, 20",
		image: "/images/blogs/blog_3.png",
		title: "Supporting FIA People’s Assemblies to Fight Inequality ",
		desc: "At CRI Index one of our central aims is to put powerful data and evidence in the hands of activists so they can demand change from their governments. As the CRI Index launches, we are joining the Fight to Inequality Index as they mobilise across the globe with a series of People’s Assemblies organised by national alliances across Africa, Asia, Latin America, Europe, and a Global Assembly."
	},
	{
		id: 4,
		author: "Kwesi W. Obeng",
		comments: 3,
		date: "01 Sep, 20",
		image: "/images/blogs/blog_4.png",
		title: "The CRII in West Africa",
		desc: "Kofi Annan, the former UN Secretary-General, in a New York Times op-ed on May 9, 2013 titled Stop the plunder of Africa, observed that, “the rising tide of wealth is not lifting all boats…few governments [in Africa] have used the increased revenues generated by resource exports to counteract rising inequality, build better healthcare and education systems or strengthen smallholder agriculture."
	}
]