import { ActionType } from 'typesafe-actions'
import { IInequalityState, Types } from './interface'
import { BaseReducer } from '../base/reducer'
import { IInequality } from '../../interfaces/inequality'
import { InequalityService } from '../../services/inequality'

const baseReducer = new BaseReducer<IInequality>(InequalityService.getName())
const initialState: IInequalityState = Object.assign(baseReducer.initialState, {
  geoInfo: {
    country_name: "",
	  country_code_iso3: ""
  }
})

const inequality = (state = initialState, { type, payload }: ActionType<any>) => {
  const newState: IInequalityState = state
  switch (type) {
    case Types.GEO_INFO:
      newState.geoInfo = payload;
      break

    default:
      baseReducer.dispatch(newState, { type, payload }).catch()
      break
  }

  return Object.assign({}, state, newState)
}

export default inequality
