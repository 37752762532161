import Axios, { Method, AxiosRequestConfig } from 'axios'

interface IAuthentication {
  token: string,
  expireIn: string,
  tokenType: string,
  refreshToken: string
}

export class ApiService {
  // // Where we save login credentials for user so that we can authenticate with server
  // static readonly CREDENTIALS_STORAGE_KEY: string = 'savedCredentials'

  // // Where we save the current access token and it's expiration so we can avoid hitting the server
  // private static readonly AUTH_STORAGE_KEY: string = 'savedAuth'

  private static authentication: IAuthentication | null

  private static readonly axios = Axios.create({
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      // 'Access-Control-Allow-Credentials': 'true',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
      // 'Access-Control-Allow-Headers': '*'
    },
    baseURL: process.env.REACT_APP_BASE_URL
  })

  private static isDeviceOffline (): boolean {
    // return ApiService.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline;
    return false
  }

  // private onError(observer, error): void {
  //   // observer.error(error);
  // }

  // private onSuccess(observer, response): void {
  //   // observer.next(response);
  // }

  private static requiresUserCredentialsRequest (endpoint: string) {
    if (endpoint.startsWith('auth')) {
      return false
    }

    return true
  }

  static ensureAuthenticated (requireUserCredentials: boolean) {
    if(process.env.REACT_APP_UI_TEST) {
      return true;
    }

    return requireUserCredentials ? (ApiService.authentication ? true : false) : true
  }

  static async request (method: Method, url: string, data?: any, transformRequest?: (parms: any) => any, transformResponse?: (data: any) => any) {
    if (ApiService.isDeviceOffline()) {
      // observer.error();
      return
    }

    // endpoint += getQueryString(queryParams)
    // const requireUserCredentials = ApiService.requiresUserCredentialsRequest(url)
    // const ensureAuthenticated = ApiService.ensureAuthenticated(requireUserCredentials)

    // if (!ensureAuthenticated) {
    //   return
    // }

    const config: AxiosRequestConfig = {
      url,
      method,
      data
      // transformRequest,
      // transformResponse
    }

    // if (requireUserCredentials) {
    //   config.headers = {
    //     Authorization: `Bearer ${ApiService.authentication?.token}`
    //   }
    // }

    // commit('setSaving', true)
    try {

      const response = await ApiService.axios(config)
      const { status, data } = response

      // commit('setSaving', false)
      if (status === 200) {
        // commit(`update${firstCharUpperCase(modelName)}`, updatedClient)
        return transformResponse ? transformResponse(data) : data;
      } if ([401, 403].indexOf(status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api

        // ApiService.logout().catch()
        window.location.reload()
      } else {
        // return new ServerError()
      }
    } catch (e) {
      // commit('setSaving', false)
      // return new NetworkError()
    }
  }

  // static async login (UserName: string, Password: string) {
  //   ApiService.authentication = await ApiService.request('POST', 'auth/Authenticate', { UserName, Password })
  // }

  // static async logout () {
  //   await ApiService.request('POST', 'auth/logout', { })
  //   ApiService.authentication = null
  // }
}
