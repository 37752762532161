export enum InequalityType {
	overall = 'Overall Index Score',
	spendings = 'Public Services Pillar',
	tax = 'Progressive Tax Pillar',
	labour = 'Workers Rights Pillar'
}

export interface IInequality {
	code: string,
	name: string,
	regionCode: string,
	rank: {
		[InequalityType.overall]: number,
		[InequalityType.spendings]: number,
		[InequalityType.tax]: number,
		[InequalityType.labour]: number
	}
}

// export const InequalityLabel = {
// 	[InequalityType.overall]: 'Overall Index Score',
// 	[InequalityType.spendings]: 'Public Services Pillar',
// 	[InequalityType.tax]: 'Progressive Tax Pillar',
// 	[InequalityType.labour]: 'Workers Rights Pillar'
// }

export const NoDataColor = 'rgb(122, 122, 122)';
export const InequalityColors = {
	[InequalityType.overall]: ['rgb(204, 234, 247)', 'rgb(106, 195, 232)', 'rgb(9, 155, 218)'],
  [InequalityType.spendings]: ['rgb(252, 223, 209)', 'rgb(247, 161, 121)', 'rgb(241, 99, 33)'],
  [InequalityType.tax]: ['rgb(211, 230, 199)', 'rgb(148, 194, 118)', 'rgb(85, 158, 37)'],
  [InequalityType.labour]: ['rgb(250, 203, 220)', 'rgb(241, 101, 151)', 'rgb(231, 0, 81)']
}

export const InequalityText = {
	[InequalityType.overall]: "The Overall Index Score combines all three core pillars on which the index is based: social spending, progressive taxation policies and labour rights.",
  [InequalityType.spendings]: "The public services pillar combines indicators for the levels of public services spending, the coverage of the services, and their impact on inequality.",
  [InequalityType.tax]: "The tax pillar combines the indicators for the progressivity of the main taxes, the country’s success in collecting taxes, and their impact on inequality.",
  [InequalityType.labour]: "The labour pillar combines the indicators for policies on labour rights, women’s rights and the minimum wage, the formally employed workers these apply to, and the inequality of wages."
}

export interface IGeoInfo {
	country_name: string,
	country_code_iso3: string
}