import React from 'react'
import { Route, Switch } from 'react-router-dom'
import adminRoutes from '../routes/adminroutes'

class Blanklayout extends React.Component {
  render () {
    return (
      <div className='admin'>
        <Switch>
          {adminRoutes.map((prop, key) => {
            return <Route path={prop.path} component={prop.component} key={key} />
          })}
        </Switch>
      </div>
    )
  }
}
export default Blanklayout
