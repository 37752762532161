import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import Header from './layout-components/header/header'
import Footer from './layout-components/footer/footer'
import connectStore from '../store'
import { IStoreProps } from '../store/interface'
import ThemeRoutes from '../routes/router'

interface IState {
  isOpen: boolean,
  width: number
}

interface IProps extends IStoreProps, RouteComponentProps {}

class Fulllayout extends React.Component<IProps, IState> {
  render () {
    return (
      <div id='main-wrapper'>
        <Header />

        <Switch>
          {ThemeRoutes.map((prop, key) => {
            return (
              <Route
                path={prop.path}
                component={prop.component}
                key={key}
                exact
              />
            )
          })}
        </Switch>

        <Footer />
      </div>
    )
  }
}

export default connectStore(Fulllayout)
