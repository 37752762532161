import { combineReducers } from 'redux'
import feature from './feature/reducer'
import inequality from './inequality/reducer'
import countryBrief from './countryBrief/reducer'
import blog from './blog/reducer'

import { connectRouter } from 'connected-react-router'

const reducers = (history: any) => combineReducers({
  router: connectRouter(history),
  feature,
  inequality,
  countryBrief,
  blog
})

export default reducers
