export const ExporeDataUrl = "https://drive.google.com/file/d/1iwTzakklNPbf9EhcI9fXkM02IfVNR_Mc/view"
export const ReportUrl = "http://powerofpublic.org/Inequality_Index_Reports/index.html"

export enum SortOrder {
  ASC,
  DESC
}

// export function convertByteToJpg (imgSrc: string): string {
//   if (imgSrc && imgSrc.startsWith('data:image/')) {
//     return imgSrc
//   }
//   return `data:image/JPEGbase64,${imgSrc}`
// }

// export async function presentToast (message, toastCtrl) {
//   let toast = await toastCtrl.create({
//     message: message,
//     duration: 3000,
//     position: 'middle'
//   })

//   await toast.present()
// }

// export async function presentAlert(message, header, alertController) {
//   const alert = await alertController.create({
//     header: header || 'Error',
//     message: message,
//     buttons: ['OK']
//   })

//   await alert.present()
// }

export function nullOrUndefined (prop: any): boolean {
  return prop === undefined || prop === null
}

export function getQueryString (obj: any, allowNullAndUndefined = false, allowEmpty = false): string {
  let query = ''
  if (!nullOrUndefined(obj)) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && (allowNullAndUndefined || !nullOrUndefined(obj[key])) && (allowEmpty || obj[key]) !== '') {
        query += ((!/\?/.test(query) ? '?' : '&') + key + '=' + encodeURIComponent(obj[key]))
      }
    }
  }
  return query
}

// export function setTimeToDate (time: Date, date: Date) {
//   date = date || new Date()
//   date.setHours(time.getHours())
//   date.setMinutes(time.getMinutes())
//   return date
// }

// export function setTimeStrToDate (time: string, date?: Date): Date {
//   date = date || new Date()
//   if (!time) {
//     return date
//   }

//   const splits: string[] = time.split(':')
//   if (splits.length !== 3) {
//     return date
//   }

//   date.setHours(+splits[0])
//   date.setMinutes(+splits[1])
//   date.setSeconds(+splits[2])

//   return date
// }

export function sortArray<T> (array: T[], key: string, order?: SortOrder) {
  return array.sort((a: T, b: T) => {
    if (SortOrder.DESC === order) {
      return (b as any)[key].toString().localeCompare((a as any)[key].toString())
    } else {
      return (a as any)[key].toString().localeCompare((b as any)[key].toString())
    }
  })
}

export function toArray<T> (map: Map<number, T>) {
  const list: T[] = []
  map.forEach( (item) => list.push(item))
  return list
}