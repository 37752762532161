import { action, BaseActions } from '../base/actions'
import { IInequality } from '../../interfaces/inequality'
import { IInequalityActions, Types } from './interface'
import { InequalityService } from '../../services/inequality'

const inequalityService = new InequalityService()
const inequalityActions: IInequalityActions = {
  ...BaseActions.getActions<IInequality>(inequalityService),
  getGeoInfo: () => (dispatch: any) => {
    inequalityService.getGeoInfo().then(geoInfo => {
      dispatch(action(Types.GEO_INFO, geoInfo))
    })
  },
}

export default inequalityActions
