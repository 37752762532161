import { BaseActions } from '../base/actions'
import { IFeature } from '../../interfaces/feature'
import { IFeatureActions } from './interface'
import { FeatureService } from '../../services/feature'

const featureService = new FeatureService()
const featureActions: IFeatureActions = {
  ...BaseActions.getActions<IFeature>(featureService)
}

export default featureActions
