export interface IBaseActions<T> {
  fetch: () => void,
  get: (id: number | string) => void,
  // add: (item: T) => void,
  // update: (item: T) => void,
  // delete: (id: number) => void
}

export enum BaseTypes {
  FETCH = 'FETCH',
  GET = 'GET',
  // ADD = 'ADD',
  // UPDATE = 'UPDATE',
  // DELETE = 'DELETE'
}

export interface IBaseState<T> {
  list: Array<T>,
  item: T | null,
  name: string
}

export function getType(type: string, prefix: string) {
  return `${prefix}_${type}`
}