import React from 'react';
import '../assets/scss/criInAction.scss';

import Blog from '../components/blog'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { blogList } from '../interfaces/blog';
import { Link } from 'react-router-dom';

function CRIInAction() {
	return (
		<section className="cri-in-action">
			<Carousel showArrows={true} autoPlay={true} infiniteLoop={true}>
				{blogList.map((blog) => {
					return (
						<Link to={`/blog/${blog.id}`}>
							<div className="latest-events" key={blog.id}>
								<img className="banner" src={`/images/blogs/${blog.title}.jpg`} alt={blog.title} />
								<div className="row desc">
									<p className="text-4">{blog.title}</p>
								</div>
							</div>
						</Link>
					)
				})}
			</Carousel>
			<div className="container">
				<div className="row clearfix">
					{blogList.map((blog, key) => {
						return <div className="col-sm-6"><Blog key={key} {...blog} ></Blog></div>
					})}
				</div>
			</div>
		</section>
	);
}

export default CRIInAction;
