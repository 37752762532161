import React, { useRef } from 'react';
import '../assets/scss/accordian.scss';
import IAccordian from '../interfaces/accordian';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

interface IProps {
  list: IAccordian[]
}

function Accordian(props: IProps) {
  const el = useRef(null);
  const onChange = (items: any[]) => {
    if (items.length === 0) {
      return;
    }

    (el.current as any).querySelectorAll(".accordion__item")[items[0]].scrollIntoView();
  }

	return (
    <div ref={el}>
      <Accordion className="accordian" allowZeroExpanded={true} onChange={onChange}>
        {props.list.map((item, index) => {
          return (
            <AccordionItem uuid={`${index}`} key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {item.title}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel dangerouslySetInnerHTML={{__html: item.desc}}>
              </AccordionItemPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
    </div>
	);
}

export default Accordian;
