import React, { useEffect, useRef, useState } from 'react';
import '../assets/scss/countryBrief.scss';

import { InequalityType } from '../interfaces/inequality';
import { ExporeDataUrl } from '../shared/utils';
import connectStore from '../store';
import { IStoreProps } from '../store/interface';

function CountryBrief (props: IStoreProps) {
	const { geoInfo, list } = props.inequalityState
	const brief = props.countryBriefState.item

	const [country, setCountry] = useState({
    code: "",
		name: "",
		regionCode: "",
		rank: {},
		inequality: {}
  });
	const countryEl = useRef(null);

	const { inequalityActions, countryBriefActions } = props
	useEffect(() => {
		inequalityActions.getGeoInfo()
		inequalityActions.fetch()
	}, [inequalityActions])

	useEffect(() => {
		if (country.code) {
			countryBriefActions.get(`${country.code}.html`)
		}
	}, [countryBriefActions, country])

	useEffect(() => {
		if (geoInfo.country_code_iso3) {
			let defaultCountry: any = list.find(c => c.code === geoInfo.country_code_iso3);
			if (!defaultCountry) {
				defaultCountry = list.find(c => c.code === 'GBR')
			}
			setCountry(defaultCountry);
		}
	}, [geoInfo, list])

	function onChangeCountry (name: string) {
		const country: any = list.find(c => c.name === name)
		if (country) {
			setCountry(country);
		}

		const el: any = countryEl.current
		el.value = ""
	}

	function getStates(keyList: InequalityType[]) {
		return keyList.map( (key) => {
			return (
				<span key={key} className={`state ${key === InequalityType.overall ? 'white' : ''}`}>
					<span className="number">#{(country.rank as any)[key]}</span>
					<span>{key}</span>
				</span>
			)
		})
	}

	if (!country) {
		return ""
	}

	return (
		<section className="country-brief">
			<div className="profile-upper">
				<div className="profile-banner">
				</div>
				<div className="profile-d container">
					<div className="u-name">{country.name}</div>
					<div className="u-list">
						<select ref={countryEl} onChange={e => onChangeCountry(e.currentTarget.value)}>
							<option value="">Change country</option>
							{list.map(c => {
								return <option key={c.name} value={c.name}>{c.name}</option>
							})}
						</select>
					</div>
					<div className="profile-pic">
						{country.code ? <img src={`/images/flags/${country.code}.png`} alt="test" /> : ""}
					</div>
					<div className="state-left">
						{getStates([InequalityType.overall, InequalityType.spendings])}
					</div>
					<div className="state-right">
						{getStates([InequalityType.tax, InequalityType.labour])}
					</div>
				</div>
			</div>
			<div className="container">
				<div className="black-grd">
					{ brief ? (
						<div dangerouslySetInnerHTML={{__html: brief}} ></div>
					) : (
						<div className="text-center">
							what is your government doing to fight inequality? <a href={ExporeDataUrl} rel="noopener noreferrer" target="_blank">Click here</a> to explore the data and find out more.
						</div>
					)}
				</div>
			</div>
		</section>
	);
}

export default connectStore(CountryBrief);
