import { BaseService } from './baseService'
import { ApiService } from './api'
import { IInequality, IGeoInfo, InequalityType } from '../interfaces/inequality'

declare const d3: any

export class InequalityService extends BaseService<IInequality> {
  static getName () {
    return 'inequality'
  }

  get name () {
    return InequalityService.getName()
  }

  protected getUrl () {
    return `/data/inequality.csv`;
  }

  protected transformResponse (data: any) {
    const countryList: IInequality[] = [];

    for (let key in data) {
      const item = data[key];
      const countryItem: IInequality = {
        code: item["Three-letter country code"],
        regionCode: item["Region"],
        name: item["CRI Country Name"],
        rank: {
          [InequalityType.overall]: item['OVERALL RANK'],
          [InequalityType.spendings]: item['PUBLIC SERVICES RANK'],
          [InequalityType.tax]: item['TAX RANK'],
          [InequalityType.labour]: item['LABOUR RANK']
        }
      }

      countryList.push(countryItem);
    }

    return countryList;
  }

  public async list (params?: any) {
    let data: IInequality[] = await new Promise( (resolve) => {
      d3.csv(this.getUrl(), (result: any) => {
        delete result.columns;
        resolve(result);
      });
    })

    return this.transformResponse(data);
  }

  public async getGeoInfo() {
    const url = 'https://ipapi.co/json/';
    const data: IGeoInfo = await ApiService.request('GET', url, {});
    return data;
  }
}
