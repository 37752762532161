import { ApiService } from './api'
import { getQueryString } from '../shared/utils'

export abstract class BaseService<T> {
  abstract get name (): string;

  protected transformRequest (data: any) {
    return typeof data === "object" ? { ...data } : data
  }

  protected transformResponse (data: any) {
    return typeof data === "object" ? { ...data } : data
  }

  protected getUrl () {
    return `/${this.name}`;
  }

  private getUrlWithId(id: number | string) {
    return `${this.getUrl()}/${id}`
  }

  public async list (params?: any) {
    let url = this.getUrl()
    url += getQueryString(params)

    const data: T[] = await ApiService.request('GET', url, {}, this.transformRequest, this.transformResponse)
    return data || []
  }

  public async get (id: number | string, params?: any) {
    let url = this.getUrlWithId(id)
    url += getQueryString(params)

    const data: T = await ApiService.request('GET', url, {}, this.transformRequest, this.transformResponse)
    return data
  }

  // public async add (item?: any) {
  //   const url = getUrl(this.name)
  //   const data = await ApiService.request('POST', url, item, this.transformRequest, this.transformResponse)
  //   return data
  // }

  // public async update (item: any) {
  //   const url = getUrlWithId(this.name, item.id)
  //   const data = await ApiService.request('POST', url, item, this.transformRequest, this.transformResponse)
  //   return data
  // }

  // public async delete (id: number) {
  //   const url = getUrlWithId(this.name, id)
  //   const data = await ApiService.request('DELETE', url)
  //   return data
  // }
}
