import React, { useRef } from 'react'

import { Link } from 'react-router-dom'
import connectStore from '../../../store'

import '../../../assets/scss/header.scss';
// import shape_5 from '../../../assets/images/header/shape_5.png'
// import image from '../../../assets/images/header/image.png'
import shape_10 from '../../../assets/images/header/shape_10.png'
import shape_11 from '../../../assets/images/header/shape_11.png'
import shape_13 from '../../../assets/images/header/shape_13.png'
import shape_12 from '../../../assets/images/header/shape_12.png'
import layer_6 from '../../../assets/images/header/layer_6.png'
import layer_8 from '../../../assets/images/header/layer_8.jpg'
import Routes from '../../../routes/router';
import { IRouteProps } from '../../../routes/interface';

const getClassName = (route: IRouteProps) => {
  let classList = [];
  if (route.isGreen) {
    classList.push('btn-green');
  }

  return classList;
}


interface ISocialMedia {
	url: string,
	image: string
}

const socialMediaList: ISocialMedia[] = [
  {
    url: "https://www.facebook.com/Oxfam",
    image: shape_10
  },
  {
    url: "https://twitter.com/Oxfam",
    image: shape_11
  },
  {
    url: "https://www.pinterest.co.uk/oxfamgb/",
    image: shape_13
  },
  {
    url: "https://www.instagram.com/oxfaminternational/",
    image: shape_12
  }
]

function Header() {
  const navEl = useRef(null);

  return (
    <div className="bg header">
      <section className="top-band">
        <div className="container">
          <div className="row match-height-bootstrap-row">
            <div className="col-md-2 col-sm-4 col-xs-6 col-md-offset-2">
              <div className="col-data-wrapper clearfix">
                {/* <img className="shape" src={shape_5} alt="" />
                <p className="text">Language: Eng</p>
                <img className="text-2" src={image} alt="}" title="}" /> */}
              </div>
            </div>
            <div className="col-sm-4 col-xs-6 col-sm-offset-4">
              <div className="col-data-wrapper-2 no-space-between-inline-blocks clearfix">
                {socialMediaList.map((s, i) => {
                  return (
                    <a key={i} href={s.url} target="_blank" rel="noopener noreferrer">
                      <img className="shape-icon" src={s.image} alt="" />
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="search">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 clearfix">
              <div className="col-data-wrapper-4 clearfix">
                <img className="layer-6" src={layer_6} alt="" />
                <img className="layer-7" src={layer_8} alt="" />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="clearfix">
                <div className="navigation">
                  <nav className="nav-2">
                    <div className="navbar-header navbar-default">
                      <button type="button" ref={navEl} className="navbar-toggle" data-toggle="collapse" data-target="#nav-2" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="my-1 mx-2 close">X</span>
                      </button>
                    </div>
                    <div className="collapse navbar-collapse" id="nav-2">
                      <ul className="nav navbar-nav nav-list clearfix">
                        {Routes.filter(r => r.name !== "Blog").map((r, key) =>
                          <li key={key} className={getClassName(r).join(' ')} onClick={() => { (navEl.current as any).click() }}>
                            <p className="nav-item">
                              {r.redirect ? (
                                <a href={r.path} target="_blank" rel="noopener noreferrer">{r.name}</a>
                              ) : (
                                <Link to={r.path}>{r.name}</Link>
                              )}
                            </p>
                          </li>
                        )}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default connectStore(Header)
