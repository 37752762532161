import { BaseService } from './baseService'
import { IFeature } from '../interfaces/feature'

export class FeatureService extends BaseService<IFeature> {
  static getName () {
    return 'feature'
  }

  get name () {
    return FeatureService.getName()
  }

  protected getUrl () {
    return `/data/feature.json`;
  }

  transformResponse (data: any) {
    return data.features;
  }
}
