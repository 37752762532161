import shape_64 from '../assets/images/home/shape_64.jpg';
import shape_58 from '../assets/images/home/shape_58.jpg';
import shape_61 from '../assets/images/home/shape_61.jpg';

export interface IRegion {
  center: number[],
  zoom: number,
  name: string,
  code: string,
  image: string
}

export const RegionList: IRegion[] = [
  {
    center: [134.502775, -25.730655],
    zoom: 2,
    name: 'East Asia and Pacific',
    code: 'EAP',
    image: shape_58
  },
  {
    center: [20.032426, 41.141353],
    zoom: 2,
    name: 'Europe & Central Asia',
    code: 'ECA',
    image: shape_61
  },
  {
    center: [-65.175361, -35.446821],
    zoom: 3,
    name: 'Latin America and Caribbean',
    code: 'LAC',
    image: shape_64
  },
  {
    center: [29.844462, 26.506620],
    zoom: 3,
    name: 'Middle East and North Africa',
    code: 'MENA',
    image: shape_58
  },
  {
    center: [-112.599438, 45.705630],
    zoom: 3,
    name: 'North America',
    code: 'NAM',
    image: shape_61
  },
  {
    center: [66.086690, 33.856399],
    zoom: 4,
    name: 'South Asia',
    code: 'SA',
    image: shape_64
  },
  {
    center: [17.470573, -12.245869],
    zoom: 3,
    name: 'Sub-Saharan Africa',
    code: 'SSA',
    image: shape_58
  }
]

export const GlobalRegion: IRegion = {
  center: [0, 44],
  zoom: 1,
  name: 'Global',
  code: '',
  image: ''
}