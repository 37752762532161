import React from 'react'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from './routes/PrivateRoutes'
import Blanklayout from './layouts/blanklayout'
import indexRoutes from './routes'
import { configureStore, history } from './store'
import './assets/scss/app.scss'
import { ConnectedRouter } from 'connected-react-router'

function App () {
  // const history = createBrowserHistory()
  history.listen(() => {
    window.scrollTo(0, 0);
  })

  return (
    <Provider store={configureStore({})}>
      <ConnectedRouter history={history}>
        <Switch>
            <Route path='/admin/login' component={Blanklayout} />
            {indexRoutes.map((prop, key) => {
              return <PrivateRoute path={prop.path} key={key} component={prop.component} />
            })}
        </Switch>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
