import { IRouteProps } from './interface'
// import AdminRoutes from './adminroutes'

import Home from '../views/home'
import About from '../views/about'
import RecentBlog from '../views/recentBlog'
import CountryBrief from '../views/countryBrief'
import CRIInAction from '../views/criInAction'
// import ExploreData from '../views/exploreData'
import Faqs from '../views/faqs'
import { ExporeDataUrl, ReportUrl } from '../shared/utils'

// const admins: IRouteProps[] = []
// admins.concat(AdminRoutes)

const Routes: IRouteProps[] = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: ExporeDataUrl,
    name: 'Explore Data',
    redirect: true
  },
  {
    path: '/indexInAction',
    name: 'Index In Action',
    component: CRIInAction
  },
  {
    path: '/countryProfiles',
    name: 'Country Profiles',
    component: CountryBrief
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/blog/:id',
    name: 'Blog',
    component: RecentBlog
  },
  {
    path: ReportUrl,
    name: 'Report',
    redirect: true,
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: Faqs
  },
  // {
  //   path: '/',
  //   name: 'Take Action',
  //   component: Home,
  //   isGreen: true
  // },
]

export default Routes
