import { IInequality, IGeoInfo } from '../../interfaces/inequality'
import { IBaseState, IBaseActions } from '../base/interface'

export interface IInequalityActions extends IBaseActions<IInequality> {
  getGeoInfo: () => void
}

export enum Types {
  GEO_INFO = 'GEO_INFO',
}

export interface IInequalityState extends IBaseState<IInequality> {
  geoInfo: IGeoInfo
}
