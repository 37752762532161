import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStore, applyMiddleware, bindActionCreators, compose } from 'redux'
import thunk from 'redux-thunk'

import { routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'

import reducers from './reducer'
import { IStoreState } from './interface'

import inequalityActions from './inequality/actions'
import featureActions from './feature/actions'
import countryBriefActions from './countryBrief/actions'
import blogActions from './blog/actions'

export const history = createHashHistory({
  hashType: 'slash',
  getUserConfirmation: (message, callback) => callback(window.confirm(message))
});

export function configureStore(initialState = {}) {
  const store = createStore(
    reducers(history), // root reducer with router state,
    initialState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk
      ),
    ),
  );

  return store;
}

const connectStore: any = (component: ComponentType<any>) => {
  return connect((state: IStoreState) => ({
    inequalityState: state.inequality,
    featureState: state.feature,
    countryBriefState: state.countryBrief,
    blogState: state.blog
  }),
  (dispatch: any) => ({
    inequalityActions: bindActionCreators((inequalityActions as any), dispatch),
    featureActions: bindActionCreators((featureActions as any), dispatch),
    countryBriefActions: bindActionCreators((countryBriefActions as any), dispatch),
    blogActions: bindActionCreators((blogActions as any), dispatch),
  }), null, { forwardRef: true })(component)
}

export default connectStore
