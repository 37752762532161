import { ActionType } from 'typesafe-actions'
import { IFeatureState } from './interface'
import { BaseReducer } from '../base/reducer'
import { IFeature } from '../../interfaces/feature'
import { FeatureService } from '../../services/feature'

const baseReducer = new BaseReducer<IFeature>(FeatureService.getName())
const initialState: IFeatureState = Object.assign(baseReducer.initialState, {

})

const feature = (state = initialState, { type, payload }: ActionType<any>) => {
  const newState: IFeatureState = state
  switch (type) {
    default:
      baseReducer.dispatch(newState, { type, payload }).catch()
      break
  }

  return Object.assign({}, state, newState)
}

export default feature
