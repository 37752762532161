import { BaseTypes, IBaseActions, getType } from './interface'
import { BaseService } from '../../services/baseService'

export function action (type: string, payload: any) {
  if (payload !== null || payload !== undefined) {
    return { type, payload }
  }

  return { type }
}

export class BaseActions {
  static getActions<T> (service: BaseService<T>): IBaseActions<T> {
    return {
      fetch: () => (dispatch: any) => {
        service.list().then(list => {
          // const map = new Map<number, T>()
          // list.forEach((item: any) => {
          //   map.set(item.id, item)
          // })

          dispatch(action(getType(BaseTypes.FETCH, service.name), list))
        })
      },
      get: (id: number | string) => (dispatch: any) => {
        service.get(id).then(item => {
          dispatch(action(getType(BaseTypes.GET, service.name), item))
        })
      },
      // add: (item: T) => (dispatch: any) => {
      //   service.add(item).then(itemToAdd => {
      //     dispatch(action(getType(BaseTypes.ADD, service.name), itemToAdd))
      //   })
      // },
      // update: (item: T) => (dispatch: any) => {
      //   service.update(item).then(itemToUpdate => {
      //     dispatch(action(getType(BaseTypes.UPDATE, service.name), itemToUpdate))
      //   })
      // },
      // delete: (id: number) => (dispatch: any) => {
      //   service.delete(id).then(() => {
      //     dispatch(action(getType(BaseTypes.DELETE, service.name), id))
      //   })
      // }
    }
  }
}
