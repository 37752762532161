import React from 'react';

import '../assets/scss/faqs.scss';
import Accordian from '../components/accordian';

import IAccordian from '../interfaces/accordian';

const accordianList: IAccordian[] = [
	{
		title: "How did you choose the pillars? What about other actions that fight inequality?",
		desc: `
			<p>The Index seeks to track the three core policy areas that are strongly correlated with reductions in inequality, as a proxy for a greater commitment by governments (the <a href="https://policy-practice.oxfam.org.uk/publications/the-commitment-to-reducing-inequality-index-a-new-global-ranking-of-governments-620316" target="_blank">2017 Index report</a> gives a good overview of the evidence which underpins the Index).</p>
			<p>The index does not seek to include everything that is relevant to fighting inequality, as that would be impossibly complex. It gives an indication of how well governments fight inequality, rather than a comprehensive assessment. This is very typical of indexes of this type. For example, the UN Human Development Index, which measures just three things as a proxy for overall commitment to human development.</p>
			<p>We do also know that other forms of policy action on fighting inequality is crucial - such as wealth taxes, access to finance and spending on social housing – but are not included due to lack of data or methodological considerations. A good example of this is land inequality which we know is deeply relevant to inequality, and particularly wealth inequality. Sadly, the quality of data available on land ownership worldwide does not allow for its inclusion in the index.</p>
		`
	},
	{
		title: "What are the data that make up the Index? Where are the data from?",
		desc: `
			<p>The index is painstakingly compiled through thousands of data points taken from primary country budget documents and laws, or from secondary published sources from international organisations, and other sources of analysis and data such as the OECD and the ILO. One set of indicators are directly supplied to us from the database of Labour Rights Indicators set up by the Center for Global Workers’ Rights (CGWR) at Penn State University. The data is then meticulously checked and quality controlled, firstly by DFI and then by Oxfam and partners at country level.</p>
			<p>The data for the 2020 Index mostly refer to 2019, though as the report states some countries’ data on social spending and coverage, and labour rights, are from older years. The CRII 2020 exposes a number of serious data gaps (i.e. the impact of spending on education, health and social protection service coverage and on inequality; the amount of taxes that could be collected; and, the coverage and enforcement of labour rights). DFI and Oxfam call for these gaps to be urgently filled. For more details of sources for all data see the 2020 methodology document.</p>
		`
	},
	{
		title: "My country has a ranking it doesn’t seem to deserve, why is this?",
		desc: `
			<p>Perceptions of key issues and trends in countries may be different for different people. For example, a country could score well on the taxation pillar because, according to objective data, it has a more progressive tax system than others. However, the key policy debate in this country could be focused on recent cuts in income taxes or increases in value-added tax (VAT), which make the system become less progressive. More detailed knowledge of country-specific policies and longer-term trends is essential to complement the scores of the index. We encourage you to read the <a href="/countryProfiles">Country Profiles</a> where available, which adds this context and colour.</p>
			<p>A country may also have very high levels of inequality, but do well on the Index; or, they have low inequality but score weakly on the CRII. Overall, successive CRIIs have found a broad correlation between CRI scores and inequality, but for individual countries this relationship can also diverge considerably, and produce counter-intuitive results (these relationships are discussed at some length in the first version of the <a href="https://policy-practice.oxfam.org.uk/publications/the-commitment-to-reducing-inequality-index-a-new-global-ranking-of-governments-620316" target="_blank">report</a>). For instance, some countries with high inequality do well in the CRI Index on implementing policies, because either they have to work harder than other countries to overcome historical legacies (i.e. South Africa with high inequality as a legacy of apartheid) or they may have only recently enacted policies which are not yet fully impacting on inequality. </p>
			<p>Conversely, some countries with low inequality are on the wrong pathway to keep this low - a number of European countries, for instance, have been back-tracking for decades on historical policies that had previously worked to reduce inequality like corporate taxes or universal benefits, and are currently trading on past glories. </p>
			<p>Finally, it is also worth noting that a high ranking does not mean a country is doing everything it can to tackle inequality ―no country is doing enough to get a perfect score in the index. <p>
		`
	},
	{
		title: "Can you really compare rich, middle-income and low-income countries?",
		desc: `
			<p>Generally richer nations are nearer the top of the index (but not all). But there is still considerable variation, with some rich countries doing much worse than others.  Many of the rich countries near the top have had a history of social struggle where workers and women have fought successfully to improve their rights and, because they are wealthier, have more scope to raise income taxes and to spend these revenues on public services. They also have smaller informal sectors.</p>
			<p>Lower income countries tend to be nearer the bottom. But some are outperforming much richer countries in a number of areas. For example, Ethiopia’s does very well on a number of indicators. Ukraine and Togo have both shown in the 2020 index that you don’t need to be a rich nation to demonstrate a commitment to reducing inequality. But some lower- middle-income countries do very poorly on the index, such as Nigeria, India or Pakistan. While some of those near the very bottom have only recently got over, or are still engaged in conflict.</p>
		`
	},
	{
		title: "A number of countries don’t appear in the index, why is this?",
		desc: `
			<p>The index ranks 158 of the 195 countries recognised by the United Nations. The remaining 37 countries have been excluded from the index because there was insufficient data or significant concerns about the quality of the available data. We make every effort to include all countries in the index but the extremely poor level of public data available for some countries limits this (especially fragile and conflict-torn states and in the Middle East). </p>
		`
	},
	{
		title: "What does the 2020 index show us?",
		desc: `
			<p>This third edition of the CRI Index shows who is performing well and who isn’t fighting inequality. It recommends that all governments adopt strong anti-inequality policies on public services, tax and labour rights, to radically reduce the gap between rich and poor. It also shows that the coronavirus crisis has exposed the scale of inequality across the world and is likely to leave most countries even more unequal. The need for all governments to rapidly commit to reducing inequality has never been more urgent. The international community must support them with Special Drawing Rights, debt relief and global solidarity taxes.</p>
		`
	},
	{
		title: "But the data in the index is from 2019, before COVID hit, so how is it relevant?",
		desc: `
			<p>The 2020 Index comes out as the world continues to struggle with COVID-19, but the data for each pillar was compiled before it hit. To respond to this new reality, the Index has looked at how well different countries are coping. This has shown that where public spending on and access to health and social protection is inadequate, or where labour protections for ordinary workers are largely non-existent, then ordinary people are far more likely to have been hit hard by both the economic and health impacts of COVID-19.  More equal countries are definitely more able to cope with shocks in ways that protect ordinary people, if they have in place things like universal social protection for all workers, universal health coverage, and labour rights such as sick leave.</p>
		`
	},
	{
		title: "What can’t the index show us? What are its limitations?",
		desc: `
			<p>The index is global in nature and cannot substitute for detailed country level analysis.  Nor does it review every policy that is relevant to inequality. Nevertheless, it is measuring three areas which are proven to be incredibly important to whether a country is reducing inequality, and bases this analysis on national level data from primary sources wherever possible. It is also the case that countries that are doing well in these policy areas are far more likely to be taking progressive steps in other areas not covered by the index.</p>
		`
	},
	{
		title: "Can this year’s results be compared to those for 2018?",
		desc: `
			<p>Not directly because the CRI has been through a series of methodological improvements since 2018. Because this is only the second full edition, and it is two years since we last published, we felt that it was important to substantively review the methodology for this edition. The changes reflect both new sources of data and feedback from experts and peer reviewers. This means a direct comparison of the overall ranking is not possible, and so we avoid them in the report. But it is possible to compare rankings on specific sub-indicators, for example the level of corporation tax, or the existence of parental leave, and we have done this in the 2020 report wherever we can.</p>
		`
	},
	{
		title: "Are there improvements by some governments in fighting inequality?",
		desc: `
			<p>Overall, the editions of the CRII published so far show some improvements in policies on average: small increases in spending on public services; slightly more progress on tax systems; and improved women’s labour rights and minimum wages. Some countries such as New Zealand, South Korea and Namibia have made much more progress over recent years, and others such as Sierra Leone are beginning similar efforts. But overall, across the world, average improvements have been relatively marginal and will not make a fundamental difference to levels of inequality.</p>
		`
	},
	{
		title: "Why are you focusing on inequality when there’s so much corruption in my country?",
		desc: `
			<p>Corruption is not measured directly – which other indexes cover in detail - but the impact of policies is measured. Corruption will impact on policies like how much is spent on public services, or if that spending reaches poor people (in terms of coverage). In this way, the index does seek to address this and to equip civil society with information to hold their governments to account on inequality policies – indirectly helping tackle corruption. We are keen to look further at the link between the CRI Index and other measures of corruption and poor governance in future reports.</p>
		`
	},
	{
		title: "Why does the index rank governments on issues that require global solutions?",
		desc: `
			<p>Governments should not be allowed to hide behind the excuse that they can’t act until everyone acts. There is virtually no evidence to suggest that unilateral action to increase the national minimum wage or corporate tax rates leads to a loss of jobs or investment, and the huge variation between countries shows that individual action is possible and necessary. As the index shows, some governments have realised this and are taking actions to raise minimum wages - sadly, very few are doing enough.</p>
		`
	}
]

function Faqs() {
	return (
		<section className="faqs">
			<div className="container">
				<div className="row clearfix">
					<div className="col-md-6 text-14">
						<p><strong>Frequently Asked Question?</strong></p>
						<p className="text-green">We fight to reduce inequality</p>
						<div className="row clearfix">
							<div className="col-md-10 col-1">
								<p>The Commitment to Reducing Inequality (CRI) Index measures the actions of governments in fighting inequality through their policy choices and actions – such as their commitments to free public healthcare, guaranteeing decent wages or ensuring that the rich pay their fair share of tax.</p>
								<p>The Index ranks 158 governments on their policies and commitments in three areas - public services, tax and labour rights - proven to be pivotal to reducing inequality. Each country is scored equally on three pillars, and on three areas of action within these thematic pillars:</p>
								<ol>
									<li>Policy commitment: how committed is the governments through their policy commitments?</li>
									<li>Coverage or implementation: who is covered (or not) as a result of policy actions? How well does the government put policies on paper into practice?</li>
									<li>Impact indicators: what is the impact of policy actions on inequality? The count ry gets an overall ranking on the CRI Index, as well as for each pillar.</li>
								</ol>
								<p>For detailed information, including how each pillar is calculated, the overall Index construction and data sources, read the 2020 methodology note.</p>
								<p>The index doesn’t aim to measure inequality, instead it focuses on what each government is doing to fight inequality. This is because inequality is not inevitable or accidental. It is the result of deliberate policy choices.</p>
								<img className="icon" src="images/icon.png" alt="" title="" />
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<Accordian list={accordianList} ></Accordian>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Faqs;
