import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/blog.scss';
import { IBlog } from '../interfaces/blog';

function Blog(props: IBlog) {
	return (
		<div className="blog">
			<div className="date">{props.date}</div>
			<img className="shape" src={`/images/blogs/${props.title}.jpg`} alt={props.title} />
			<div className="title">
				{props.title}
			</div>
			<div className="info">
				<span>{props.author}</span>
				{/* <span>{props.comments} comments</span> */}
			</div>
			<div className="desc">
				{props.desc}
			</div>
			<div className="read-more">
				<Link to={`/blog/${props.id}`}>Read more</Link>
			</div>
		</div>
	);
}

export default Blog;
