import { BaseService } from './baseService'

export class BlogService extends BaseService<string> {
  static getName () {
    return 'blog'
  }

  get name () {
    return BlogService.getName()
  }

  protected getUrl () {
    return `/data/blog`;
  }
}
