import Fulllayout from '../layouts/fulllayout'
import Blanklayout from '../layouts/blanklayout'
import { IRouteProps } from './interface'

const indexRoutes: IRouteProps[] = [
    { path: '/admin', name: 'Admin', component: Blanklayout },
    { path: '/', name: 'Home', component: Fulllayout }
]

export default indexRoutes
