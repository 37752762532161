import React, { useEffect, useState } from 'react';
import '../assets/scss/home.scss';

import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import shape_38 from '../assets/images/home/shape_38.png';
import shape_40 from '../assets/images/home/shape_40.png';
import shape_42 from '../assets/images/home/shape_42.png';
import shape_44 from '../assets/images/home/shape_44.png';

import Map from '../components/map'
import RegionCarousel from '../components/regionCarousel';

import connectStore from '../store';
import { IStoreProps } from '../store/interface';

import { RegionList } from '../interfaces/region';
import { Link } from 'react-router-dom';
import { ExporeDataUrl } from '../shared/utils';
// import { Link } from 'react-router-dom';

interface IOverall {
	path: string,
	title: string,
	image: string,
	desc: string,
	redirect?: boolean
}

const overallList: IOverall[] = [
	{
		path: "/indexInAction",
		title: "Index in action",
		image: shape_40,
		desc: "The CRI Index aims to enable activists to use the data in their own campaigning and advocacy.  We also aim to put data and tools in the hands of journalists, advocates, researchers and policy makers, and encourage the use of the index by others."
	},
	{
		path: ExporeDataUrl,
		title: "Explore the index",
		image: shape_44,
		desc: "A global ranking of governments based on what they are doing to tackle the gap between rich and poor. The Index ranks 158 governments across the world on their commitment to reducing inequality. The Index measures government policies and actions in three areas that are proven to be directly related to reducing inequality; public services (education, health and social protection); taxation; and, workers’ rights.",
		redirect: true
	},
	{
		path: "/about",
		title: "About us",
		image: shape_38,
		desc: "The Commitment to Reducing Inequality Index is a joint project by Development Finance International and Oxfam International who have come together to launch this powerful tool in the fight against inequality."
	},
	{
		path: "/faqs",
		title: "Frequently asked questions",
		image: shape_42,
		desc: "To find out more about the Index please read the FAQs"
	},
]

interface ITestimonial {
	text: string,
	author: string,
	designation: string,
	institute: string
}

const testimonialsList: ITestimonial[] = [
	{
		text: "Oxfam and Development Finance International’s insightful investigation into what governments are actually doing to reduce inequality could not have come at a better time. Based on careful, systematic and scientific use of the available data, it does much more than simply rank countries to provide objective assessments of their performance on this crucial issue; it provides an urgent wake up call to all governments about what can be done in terms of taxation, spending and labour policies.",
		author: "Jayati Ghosh",
		designation: "Professor",
		institute: "Jawaharlal Nehru University, New Delhi, India",
	},
	{
		text: "Oxfam and Development Finance International’s Commitment to Reducing Inequality Index is a rigorous attempt to do so: to demonstrate the nature, the depth and the scope of the problem and the implications for public policy. It shows that every country has to make a step change",
		author: "Donald Kaberuka",
		designation: "7th President",
		institute: "African Development Bank (2005–2015)"
	},
	{
		text: "This Commitment to Reducing Inequality Index - technical though it sounds - could be a powerful tool in the hands of citizens to demand change. In the face of politician's platitudes, we can show hard facts. In the face of meaningless promises, we can show the gaping holes where policies to reduce inequality could be. Information is power, so let's use it.",
		author: "Kumi Naidoo",
		designation: "South African Activist and Former Board Chair",
		institute: "Africans Rising for Justice, Peace and Dignity and Amnesty International"
	}
]

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function Home(props: IStoreProps) {
	const [country, setCountry] = useState({
    code: "",
		name: "",
		regionCode: "",
		rank: {},
		inequality: {}
  });

	useEffect(() => {
		props.inequalityActions.getGeoInfo()
		props.inequalityActions.fetch()
		props.featureActions.fetch()
	}, [props.inequalityActions, props.featureActions])

	useEffect(() => {
		const { list, geoInfo } = props.inequalityState
		if (geoInfo.country_code_iso3) {
			let defaultCountry: any = list.find(c => c.code === geoInfo.country_code_iso3);
			if (!defaultCountry) {
				defaultCountry = list.find(c => c.code === 'GBR')
			}
			setCountry(defaultCountry);
		}
	}, [props.inequalityState])

	const overallHtml = (o: IOverall) => {
		return (
			<div>
				<img src={o.image} alt={o.title} />
				<div className="text-8">
					<div className="text-6">{o.title}</div>
					<div className="text-7">{o.desc}</div>
				</div>
			</div>
		)
	}
	return (
		<section className="home">
			<div className="banner">
				<div className="container">
					<div className="row desc">
						<div className="col-md-12">
							<p><strong>What is your country doing to fight</strong></p>
							<p className="text-5">INEQUALITY?</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="container-fluid">
					{ country ? (
						<div className="row">
							<div className="col-sm-4 text-center">
								<div className="text-10">Commitment To Reducing Inequality Ranking For</div>
								<strong className="text-11">{country.name}</strong>
							</div>
							<div className="col-sm-8 dash-border">
								{Object.keys(country.rank).map((key, i) => {
									return (
										<div key={key} className={`col-md-3 col-xs-6 ${i > 0 ? 'dash-border' : ''}`}>
											<div className="state text-center">
												<span className="number">#{(country.rank as any)[key]}</span>
												<span className="text-12">{key}</span>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					) : ''}
					<div className="row">
						<div className="col-md-12">
							<strong className="text-9">Commitment to Reducing Inequality Index</strong>
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12">
							<p className="text-12">The Commitment to Reducing Inequality (CRI) Index monitors what governments are doing through their policy commitments to reduce inequality. We believe inequality is far from inevitable. It is a policy choice and governments have considerable powers to reduce the gap between the rich and poor in their countries. The CRI Index is developed and delivered through a partnership between Development Finance International and Oxfam International, with inputs from independent experts.</p>
						</div>
					</div>
					<div className="row">
						{overallList.map(o => {
							return (
								<div key={o.title} className="col-md-6">
									<div className="overall-item">
										{o.redirect ? (
											<a href={o.path} target="_blank" rel="noopener noreferrer">
												{overallHtml(o)}
											</a>
										) : (
											<Link to={o.path} >
												{overallHtml(o)}
											</Link>
										)}
									</div>
								</div>
							)
						})}
					</div>
					<div className="row">
						<div className="col-xs-12">
							<div className="text-13">A GLOBAL RANKING OF GOVERNMENTS BASED ON WHAT THEY ARE DOING TO</div>
							<strong>TACKLE THE GAP BETWEEN RICH AND POOR</strong>
							<div className="text-7">What is your country doing to #fightinequality?</div>
						</div>
					</div>
					<MultiCarousel responsive={responsive} autoPlay={true} infinite={true}>
						{ RegionList.map(r => <RegionCarousel key={r.code} region={r} list={props.inequalityState.list} /> )}
					</MultiCarousel>
					<div className="row">
						<div className="col-xs-12 btn-more-all">
							<div className="btn-more">
								<a href={ExporeDataUrl} rel="noopener noreferrer" target="_blank">View all</a>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<strong className="text-9">TESTIMONIALS</strong>
							<Carousel showArrows={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
								{testimonialsList.map((t, key) => {
									return (
										<div key={key} className="text-16">
											<div>{t.text}</div>
											<div className="author">- {t.author}, <span className="des">{t.designation}</span>, <span className="ins">{t.institute}</span></div>
										</div>
									)
								})}
							</Carousel>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<strong className="text-9">Which country is doing the most to fight inequality?</strong>
							<div className="text-7">A global ranking of governments based on what they are doing to tackle the gap between rich and poor</div>
						</div>
					</div>
					<Map inequality={props.inequalityState.list} features={props.featureState.list} />
				</div>
			</div>
		</section>
	);
}

export default connectStore(Home);
