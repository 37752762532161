import { BaseActions } from '../base/actions'
import { ICountryBriefActions } from './interface'
import { CountryBriefService } from '../../services/countryBrief'

const countryBriefService = new CountryBriefService()
const countryBriefActions: ICountryBriefActions = {
  ...BaseActions.getActions<string>(countryBriefService)
}

export default countryBriefActions
