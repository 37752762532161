import React from 'react'
import { Route } from 'react-router-dom'
// import { ApiService } from '../services/api'

const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <Route {...rest} render= { props => {
    // const ensureAuthenticated = ApiService.ensureAuthenticated(true)
    // if (!ensureAuthenticated) {
    //   // not logged in so redirect to login page with the return url
    //   return <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }} />
    // }

    // authorised so return component
    return <Component {...props} />
  }} />
)

export default PrivateRoute
