import React from 'react'

import '../../../assets/scss/footer.scss';
// import shape_80 from '../../../assets/images/footer/shape_80.png';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render () {
    return (
      <section className="footer">
        <div className="row-13">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-xs-12">
                <div className="about-us">
                  <p className="text-title">ABOUT US</p>
                  <p className="text-desc">To know more about us <Link to="/about" className="text-style-8">Click Here</Link></p>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div className="focus-areas">
                  <p className="text-title">FOCUS AREAS</p>
                  <div className="row-4-2 clearfix">
                    <div className="wrapper-16">
                      <ul className="text-desc">
                        <li>Global</li>
                        <li>South Asia</li>
                        <li>North America</li>
                        <li>Sub-Saharan Africa</li>
                        <li>Middle East and North Africa</li>
                        <li>Methodology</li>
                        <li><a href="https://drive.google.com/uc?id=1iwTzakklNPbf9EhcI9fXkM02IfVNR_Mc&export=download" target="_blank" rel="noopener noreferrer">Download Data</a> </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div className="contact">
                  <p className="text-title">CONTACT</p>
                  <div className="clearfix">
                    {/* <img className="shape-51" src={shape_80} alt="" /> */}
                    <div className="col-14">
                      {/* <p className="text-desc">135<br />Loren ipsum,<br />Lorem Ipsum,<br />Nairobi,KENYA</p> */}
                      <p className="text-80"><a href="mailto:info@inequalityindex.org">info@inequalityindex.org</a></p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="subscribe">
                  <p className="text-title">signup for updates</p>
                  <p className="text-desc">To keep yourself updated on latest news and programs at Reliance Foundation, drop in your E-mail ID</p>
                  <input className="shape-holder-11" placeholder="Enter your Email Address" />
                  <div className="shape-holder-12">
                    submit
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="bottom-footer-band">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                inequality index 2020, All Rights Reserved. Site Crafted by <a href="https://www.odipodev.com/" rel="noopener noreferrer" target="_blank"> Odipo Dev</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default Footer
