import React, { useEffect, useRef, useState } from 'react';
import '../assets/scss/map.scss';

import { IInequality, InequalityColors, InequalityText, InequalityType, NoDataColor } from '../interfaces/inequality'
import { IFeature } from '../interfaces/feature';

import { GlobalRegion, RegionList } from '../interfaces/region'
import { worldMap } from './worldMap';

interface IProps {
  features: IFeature[],
  inequality: IInequality[]
}

const getCommitedText = (item: IInequality, type: InequalityType) => `${item.name} (Global Rank #${(item.rank as any)[type]})`

function Map (props: IProps) {
  const { features, inequality } = props
  const mapEl = useRef(null);
  const regionEl = useRef(null);
  const typeEl = useRef(null);

  const [region, setRegion] = useState(GlobalRegion.code);
  const [type, setType] = useState(InequalityType.overall);

  useEffect(() => {
    if (features.length > 0 && inequality.length > 0) {
      worldMap(mapEl.current, regionEl.current, typeEl.current, features, inequality);
    }
  }, [features, inequality])

  window.onresize = () => {
    if (features.length > 0 && inequality.length > 0) {
      const svg = mapEl.current ? (mapEl.current as any).querySelector("svg") : null;
      if (!svg) {
        return;
      }

      svg.remove()
      worldMap(mapEl.current, regionEl.current, typeEl.current, features, inequality);
    }
  };

  const legends: string[] = ['No Data', 'Low Performance', 'Moderate Performance', 'Good Performance'];
  const legendColors = [NoDataColor, ...InequalityColors[type]]

  const filterInequality = inequality.filter(i => i.regionCode === region || region === GlobalRegion.code);
  filterInequality.sort( (a: any, b: any) => {
    return a.rank[type] - b.rank[type];
  });

  const mostCommited = filterInequality.splice(0, 10)
  const leastCommited = filterInequality.reverse().splice(0, 10)

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="graph-container">
          <div className="col-md-9 graph">
            <div ref={mapEl} className={`map ${type}`}>
              <div className="col-sm-6 region">
                <select ref={regionEl} value={region} onChange={e => setRegion(e.currentTarget.value as any)}>
                  <option value={GlobalRegion.code}>{GlobalRegion.name}</option>
                  {RegionList.map(r => {
                    return (
                      <option key={r.code} value={r.code}>{r.name}</option>
                    )
                  })}
                </select>
              </div>
              <div className="col-sm-6 type">
                <select ref={typeEl} value={type} onChange={e => setType(e.currentTarget.value as any)}>
                  {Object.keys(InequalityType).map(key => {
                    return (
                      <option key={key} value={(InequalityType as any)[key]}>{(InequalityType as any)[key]}</option>
                    )
                  })}
                </select>
              </div>

              <div className="legend">
                {legends.map((l, i) => {
                  return (
                    <div key={i}>
                      <span className="rect" style={{ backgroundColor: legendColors[i] }}></span>
                      <span>{l}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="col-md-3 list-container">
            <div className="text-6">{type}</div>
            <div className="text-7">
              {InequalityText[type]}
						</div>
            <div className="list">
              <div className="text-6">Most Committed</div>
              <ol>
                {mostCommited.map((m) => <li key={m.code} className="text-7">{getCommitedText(m, type)}</li>)}
              </ol>
            </div>
            <div className="list">
              <div className="text-6">Least Committed</div>
              <ol>
                {leastCommited.map((m) => <li key={m.code} className="text-7">{getCommitedText(m, type)}</li>)}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map;
